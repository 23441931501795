.content {
  border: 1px solid rgb(128, 123, 123);
  padding: 0.5rem;
  border-radius: 5px;
}

.content-parent {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.9s;
}

.content-show {
  height: 200px;
}

.toggle {
  width: 100%;
  border: 1px solid rgb(128, 123, 123);
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #2c857d;
  color: white;
  font-size: 14px;
  text-decoration: none !important;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
