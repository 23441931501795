*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  color-scheme: light;
}

body {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-weight: 300;
  color: rgb(66, 66, 66);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font: inherit;
}

img,
picture,
/* svg, */
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font-family: inherit;
}

ul {
  list-style-type: none;
}

img {
  -webkit-user-select: none;
          user-select: none;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "DMSerifDisplay";
  font-display: swap;
  font-weight: 100 900;
  src: local("DMSerifDisplay"),
    url(/static/media/DMSerifDisplay-Regular.359da220.ttf) format("truetype");
}

.content {
  border: 1px solid rgb(128, 123, 123);
  padding: 0.5rem;
  border-radius: 5px;
}

.content-parent {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.9s;
}

.content-show {
  height: 200px;
}

.toggle {
  width: 100%;
  border: 1px solid rgb(128, 123, 123);
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #2c857d;
  color: white;
  font-size: 14px;
  text-decoration: none !important;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* BANNER */

.login-container {
  background: white;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #e8eaec;
  padding: 20px;
  width: 400px;
  margin: 0 auto;
}

.login-wrapper {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}

.login-logo {
  height: 70px;
  margin-bottom: 30px;
  cursor: pointer;
}

.login-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  color: #233534;
}

.login-error {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #ac2222;
}

/* PRODUCT PAGE */

.product-page-image-main {
  height: 440px;
  width: 440px;
  border: 1px solid #e8eaec;
  object-fit: cover;
}

.product-page-info-wrapper {
  flex: 1 1;
  margin-left: 35px;
}

.product-page-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.product-page-description {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
  width: 100%;
}

.product-page-price {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #e48832;
}

.banner-button {
  background: #179bcf;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  width: 150px;
}

.banner-button:hover {
  background: #138ab9;
}

/* PRODUCT ATTRIBUTE */

.weight-500 {
  font-weight: 500;
}

.weight-400 {
  font-weight: 400;
}

.color-orange {
  color: #e48832;
}

.already-have-account-text {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.already-have-account-text-highlight {
  font-weight: 700;
}

.account-type-option-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 10px;
}

.shopify-url-container {
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e8eaec;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 500px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.shopify-url-entry-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.shopify-url-input {
  padding: 15px;
  padding-right: 0px;
  margin-right: 5px;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  width: 200px;
  border: 0px;
  border-bottom: 2px solid #cccccc;
  color: #3a3e42;
}

.shopify-url-input-ending {
  font-size: 20px;
  font-weight: 500;
  color: #9e9e9e;
}

.orders-wrapper {
  width: 1100px;
  margin: 0 auto;
}

/* .react-tags__tags {
  background: white;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 18px;
  width: 100%;
  margin-right: 20px;
} */

div.react-tags__tags {
  position: relative;
}

/* Styles for the input */
div.react-tags__tag-input {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

div.react-tags__tag-input input.react-tags__tag-input-field,
div.react-tags__tag-input input.react-tags__tag-input-field:focus {
  font-size: 14px;
  width: 100%;
  border: 1px solid #eee;
  height: 25px;
  border-radius: 2px;
  padding: 5px;
}

/* Styles for selected tags */
div.react-tags__selected span.react-tags__tag {
  color: white;
  background: #8dc4bd;
  font-size: 14px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}

div.react-tags__selected a.react-tags__remove {
  color: white;
  margin-left: 5px;
  cursor: pointer;
}

div.react-tags__suggestions {
  position: absolute;
}

div.react-tags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

div.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.react-tags__suggestions ul li.react-tags__active-suggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.editor-class {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

