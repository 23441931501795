*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  color-scheme: light;
}

body {
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-weight: 300;
  color: rgb(66, 66, 66);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font: inherit;
}

img,
picture,
/* svg, */
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font-family: inherit;
}

ul {
  list-style-type: none;
}

img {
  user-select: none;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "DMSerifDisplay";
  font-display: swap;
  font-weight: 100 900;
  src: local("DMSerifDisplay"),
    url(./fonts/DMSerifDisplay-Regular.ttf) format("truetype");
}
